img {
    width: 100%;
    max-width: 600px;
    margin: auto;
    display: block;
}

iframe {
    width: 100%;
    max-width: 600px;

    margin: auto;
    display: block;
}


a.logo{
    max-width: 20pt;
}

.spacing{
    margin-bottom: 50px;
}


// header hero background image

div.header-bg{
    margin-bottom: 50px;
}

header.landing-header{
    min-height: 550px;
    max-height: 800px;
    background-image: url('../assets/hop-meta.png');
    background-size: cover;
    background-position: center;
}

header.header-other{
    padding-bottom: 200px;
    // max-height: 250px;
}

p.text-dark{
    color: $body-bg !important;
}

div.card a{
    color: $link-dark;
    transition: all ease 0.2s;
    font-weight: 800;
    &:hover {
      color: $highlight-color;
      text-decoration: none;
    }
}

.imgs{
    width: 200px;
    height: 220px;
    background-position: center center;
    // background-repeat: no-repeat;
    object-fit: cover;
}
