// Menus
.menu {
  @include shadow-variant(.05rem);
  background: $white;
  border-radius: $border-radius;
  list-style: none;
  margin: 0;
  min-width: $control-width-xs;
  padding: $unit-2;
  transform: translateY($layout-spacing-sm);
  z-index: $zindex-3;
  &.menu-lg {
    width: 400px;
    padding: $unit-2;
    .product-link {
      margin: 0 0 5px;
      padding: $unit-2;
      &:hover {
        background: $bg-color;
      }
    }
    .product-link:last-child {
      margin: 0;
    }
  }

  &.menu-nav {
    background: transparent;
    box-shadow: none;
  }

  .menu-item {
    margin-top: 0;
    padding: 0 $unit-2;
    position: relative;
    text-decoration: none;
    text-align: left;

    & > a {
      border-radius: $border-radius;
      color: $dark-color;
      display: block;
      margin: 0 (-$unit-2);
      padding: $unit-1 $unit-2;
      text-decoration: none;
      &:focus,
      &:hover {
        background: $secondary-color;
        color: $primary-color;
      }
      &:active,
      &.active {
        background: $secondary-color;
        color: $primary-color;
      }
    }

    .form-checkbox,
    .form-radio,
    .form-switch {
      margin: $unit-h 0;
    }

    & + .menu-item {
      margin-top: $unit-1;
    }
  }

  .menu-badge {
    align-items: center;
    display: flex;
    height: 100%;
    position: absolute;
    right: 0;
    top: 0;

    .label {
      margin-right: $unit-2;
    }
  }
}