// Display
.d-block {
  display: block;
}
.d-inline {
  display: inline;
}
.d-inline-block {
  display: inline-block;
}
.d-flex {
  display: flex;
}
.d-inline-flex {
  display: inline-flex;
}
.d-none,
.d-hide {
  display: none !important;
}
.d-visible {
  visibility: visible;
}
.d-invisible {
  visibility: hidden;
}
.text-hide {
  background: transparent;
  border: 0;
  color: transparent;
  font-size: 0;
  line-height: 0;
  text-shadow: none;
}
.text-assistive {
  border: 0;
  clip: rect(0,0,0,0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}


.no-wrap {
  white-space: nowrap;
}