.footer {
  position: relative;
  width: 100%;
  bottom: 0px;
  background: $primary-color;
  padding: 0;
  .footer-contents {
    padding-top: 5px;
    .footer-social {
      a {
        display: inline-block;
        font-size: 1.5em;
      }
    }
  }
  h5 {
    color: $white;
    a {
      margin: 0;
    }
  }
  .builtby {
    p, svg {
      display: inline-block;
    }
  }
  p {
    color: $white;
    font-size: 0.8em;
    margin: 15px 0;
  }
  a {
    color: $link-light;
    display: block;
    &:hover {
      color: $highlight-color;
      cursor: pointer;
    }
  }
}
