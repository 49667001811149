// Text colors
@include text-color-variant(".text-primary", $primary-color);

@include text-color-variant(".text-secondary", $secondary-color-dark);

@include text-color-variant(".text-gray", $secondary-color);

@include text-color-variant(".text-graydark", $gray-color);

@include text-color-variant(".text-light", $white);

@include text-color-variant(".text-dark", $body-font-color);

@include text-color-variant(".text-success", $success);

@include text-color-variant(".text-warning", $warning);

@include text-color-variant(".text-error", $error);

// Background colors
@include bg-color-variant(".bg-primary", $primary-color);

@include bg-color-variant(".bg-primary-dark", $primary-color-dark);

@include bg-color-variant(".bg-white", $white);

@include bg-color-variant(".bg-secondary", $secondary-color);

@include bg-color-variant(".bg-dark", $dark-color);

@include bg-color-variant(".bg-gray", $gray-color-light);

@include bg-color-variant(".bg-success", $success);

@include bg-color-variant(".bg-warning", $warning);

@include bg-color-variant(".bg-error", $error);


// Border colors
@include border-color-variant(".border-primary", $primary-color);
@include border-color-variant(".border-gray", $gray-color-light);

.drop-shadow {
      box-shadow: $main-shadow;
}

@include borderb-color-variant(".borderb-primary", $primary-color);

@include borderb-color-variant(".borderb-gray", $border-color);


.border-dash {
      border: 2px dashed $gray-color-light;
}

.hover-gray {
      transition: all ease 0.3s;
      &:hover {
            background: $gray-color-light;
      }
}

.hover-lightgray {
      transition: all ease 0.3s;
      &:hover {
            background: $gray-color-light;
      }
}