.header-bg {
  background-color: $primary-color;
}

.header-bg-img {
  // background-image: url("../assets/banner_wh.jpeg");
  background-color: $primary-color;
}

.nav-gradient {
  background: linear-gradient(0deg, rgba(6,14,20,0) 0%, rgba(6,14,20,1) 100%);
}

.landing-header {
  padding: 20px 0 100px;
  h1, h2 {
    margin: 0;
    color: $white;
  }
  h5 {
    color: $gray-color-light;
    margin: 20px 0;
    line-height: 1.5em;
  }
  button {
    margin: 20px 0;
  }

}


.overview {
  background: $gray-color-light;
  .overview-point {
    display: flex;
    align-items: center;
    margin: 0 0 20px;
    h4, p {
      margin: 0;
    }
    h4 {
      background: $white;
      padding: 15px;
      border-radius: 30px;
      margin-right: 20px;
      color: $primary-color;
      box-shadow: $main-shadow;
      transition: all 0.3s ease;
      &:hover {
        background: $error;
        color: $white;
      }
    }
  }
}

.landing-section {
  padding: 100px 0;
}

.landing-spacer {
  margin: 160px auto;
}

.landing-spacer-top {
  margin-bottom: 120px;
}
