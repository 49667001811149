// Divider
.divider,
.divider-vert {
  display: block;
  position: relative;

  &[data-content]::after {
    background: $white;
    color: $gray-color;
    content: attr(data-content);
    display: inline-block;
    font-size: $font-size-sm;
    padding: 0 $unit-2;
    transform: translateY(-$font-size-sm + $border-width);
  }
  &.divider-dark {
    border-top: $border-width solid $border-color;
  }
  &.divider-thick {
    border-top: 4px solid $primary-color;
  }
}

.divider {
  border-top: $border-width solid $border-color-light;
  height: $border-width;
  margin: $unit-6 0;

  &[data-content] {
    margin: $unit-6 0;
  }
}

.divider-vert {
  display: block;
  padding: $unit-4;

  &::before {
    border-left: $border-width solid $border-color;
    bottom: $unit-2;
    content: "";
    display: block;
    left: 50%;
    position: absolute;
    top: $unit-2;
    transform: translateX(-50%);
  }

  &[data-content]::after {
    left: 50%;
    padding: $unit-1 0;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}
