.nav-blurple {
  background: $primary-color;
}

.navbar {
  padding: 10px 20px;
  &.dash-nav {
    padding: 12px 0;
    .nav-links, .navbar-toggle {
      color: $dark-color;
    }.navbar-toggle {
      top: 4px;
    }
  }
}


.nav-full {
  background: $primary-color-dark;
}

.main-nav {
  list-style-type: none;
  display: none;
}

.nav-links {
  text-decoration: none;
  font-weight: 700;
  color: $link-light;
  &.active {
    color: $error;
  }
}

.main-nav li {
  text-align: center;
  margin: 15px auto;
}

.logo {
  display: flex;
  align-items: center;
  border: 0;
  h4 {
        margin: 0;
        margin-left: 14px;
        color: $white;
        font-weight: 700;
  }
  &:hover {
        border: 0;
  }
  p {
        font-size: 10px;
        background: $gray-color;
        padding: 2px 8px;
        border-radius: 5px;
        margin-left: 5px;
  }
}

.navbar-toggle {
  position: absolute;
  top: 14px;
  right: 20px;
  cursor: pointer;
  color: $white;
  font-size: 24px;
}

.active {
  display: block;
}

@media screen and (min-width: 768px) {
  .navbar {
        padding: 10px 10px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        & .dash-nav {
          padding: 20px 0;
        }
        .navbar-toggle {
              display: none;
        }
        .logo {
              margin-top: 0;
        }
        .main-nav {
              display: flex;
              margin-right: 30px;
              flex-direction: row;
              align-items: center;
              margin: 0;
              li {
                    margin: 0;
                    margin-left: 20px;
                    &.nav-divider {
                      width: 2px;
                      background: $white;
                      height: 20px;
                      &.divider-dark {
                        background: $dark-color;
                      }
                    }
                    & a.active {
                      color: $error;
                      border: 2px solid transparent;
                      text-decoration: none;
                    }
              }
        }

  }
}
