// Invite form button

// <!-- HTML !-->
// <button class="btn-custom" role="button">Button 80</button>

/* CSS */
.btn-custom {
  // background: #fff;
  backface-visibility: hidden;
  border-radius: .375rem;
  border-style: solid;
  border-width: .125rem;
  box-sizing: border-box;
  // color: #212121;
  cursor: pointer;
  display: inline-block;
  // font-family: Circular,Helvetica,sans-serif;
  // font-size: 1.125rem;
  // font-weight: 700;
  // letter-spacing: -.01em;
  // line-height: 1.3;
  padding: .5rem 1.2rem;
  position: relative;
  text-align: center;
  text-decoration: none;
  transform: translateZ(0) scale(1);
  transition: transform .2s;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;

  margin: 3rem auto;
  display: block;
  max-width: 400px;
}

.btn-custom:not(:disabled):hover {
  transform: scale(1.05);
}

.btn-custom:not(:disabled):hover:active {
  transform: scale(1.05) translateY(.125rem);
}

.btn-custom:focus {
  outline: 0 solid transparent;
}

.btn-custom:focus:before {
  content: "";
  left: calc(-1*.375rem);
  pointer-events: none;
  position: absolute;
  top: calc(-1*.375rem);
  transition: border-radius;
  user-select: none;
}

.btn-custom:focus:not(:focus-visible) {
  outline: 0 solid transparent;
}

.btn-custom:focus:not(:focus-visible):before {
  border-width: 0;
}

.btn-custom:not(:disabled):active {
  transform: translateY(.125rem);
}
